<template>
  <div class="app-wrapper">
    <div class="vertical-layout">
        <MenuPanelSide>
              <MenuSocialJet>
              </MenuSocialJet>
              <template #menu>
                <MenuSide>
                </MenuSide>
              </template>
              <template #links>
                <MenuLinks>
                </MenuLinks>
              </template>
        </MenuPanelSide>
        <div class="page-with-top-panel">
          <MenuPanelTop>
            <div class="gorizontal-menu flex-center justify-between">
              {{ route.name }}
              <!-- переверстать верхнее меню -->
              <div class="flex-center-3">
                <div
                  v-if="isMobile && authorized"
                  class="gorizontal-menu__button justify-align-center"
                ></div>
              </div>

              <div class="flex-center-3 gorizontal-menu__aside">
                <template v-if="userModule.id">
                  <div class="gorizontal-menu__aside-notifications justify-align-center">
                    <Icon name="solar:bell-bold" :color="IconColor.GRAY" width="20" height="20" />
                  </div>
                  <div class="gorizontal-menu__aside-user flex-center-3">
                    <Icon name="mingcute:user-4-fill" :color="IconColor.SKY" width="32" height="32" />
                    <span v-if="!isMobile">{{ userName || telegram || email }}</span>
                  </div>
                </template>
                <UIButtonSecondary 
                  v-else class="gorizontal-menu__aside-enter"
                  @click="userModule.loginDialogVisible = true"
                  >Войти</UIButtonSecondary
                >
              </div>
            </div>
          </MenuPanelTop>
          <div class="page-self">
            <!-- основной слот для страницы -->
            <slot></slot>
          </div>
        </div>
    </div>

    <UIOverlay :view-overlay="true" />
      <ElDialog
        v-model="userModule.loginDialogVisible"
        modal-class="authorize-modal"
        width="min(500px,85vw)"
      >
        <LoginScreen/>
      </ElDialog>
  </div>
</template>
<script setup lang="ts">
import { ElDialog } from 'element-plus';
import { IconColor } from '~/interfaces/publisher';
import { useUserStore } from '~/store/UserModule';
import { useAppStore} from '~/store/AppModule';
import { useChannelStore } from '~/store/ChannelModule';
import { useAdvertContentStore } from '~/store/AdvertContentModule';
import { useAdvertStore } from '~/store/AdvertModule';


//import { LoginScreenContext } from '~/interfaces/login/UI';

const route = useRoute();

const userModule = useUserStore();
const channelModule = useChannelStore();
const advertContentModule = useAdvertContentStore();
const appModule = useAppStore();
const advertModule = useAdvertStore();

// Запросить токен для пользователя
userModule.requestAuthKey();


window.userModule = userModule;
window.channelModule = channelModule;
window.advertContentModule = advertContentModule;
window.appModule = appModule;
window.advertModule = advertModule;

</script>

<style lang="scss">
.app-wrapper {
  height: 100%;
}

.vertical-layout {
  display: flex;
  flex-direction: row;
}

.page-with-top-panel {
  width: 100%;
  flex-grow: 1;
}

.gorizontal-menu,
.vertical-menu {
  background: $white;
}

.el-menu + .screen-wrapper {
  margin-left: 70px;

  @media (max-width: $small-width) {
    margin-left: 0;
  }
}

.gorizontal-menu {
  @apply w-full pr-4 pl-2 z-50;
  height: 70px;

  &__aside {
    @apply h-full justify-end;

    &-user {
      width: fit-content;
      color: $light-gray;
      font-weight: 500;
    }

    &-notifications {
      width: fit-content;
      height: 100%;
      padding-left: clamp(20px, 2vw, 24px);
      padding-right: clamp(20px, 2vw, 24px);
      border-left: 1px solid rgba($main, 0.3);
      border-right: 1px solid rgba($main, 0.3);

      @media (max-width: $small-width) {
        border: none;
      }
    }
  }

  &__button {
    cursor: pointer;
    margin: 6px 0px;
    width: 36px;
    height: 36px;
    border-radius: 11px;
    border: 1px solid rgba($main, 0.2);
    flex-direction: column;
    gap: 5px;
    padding-left: 8px;
    padding-right: 8px;

    > div {
      width: 100%;
      height: 2px;
      background: $main;
      border-radius: 1px;
    }
  }
}

.gorizontal-menu .gorizontal-menu__button {
  display: none;
}

@media (max-width: $small-width) {
  .app-wrapper .vertical-menu.vertical-menu.el-menu--collapse {
    border-right: none;

    &:not(.horizontal-collapse-transition) {
      transform: translateX(-300px);
    }

    img {
      display: none;
    }
  }

  .gorizontal-menu .gorizontal-menu__button {
    display: flex;

    + a {
      z-index: 1;
    }
  }

  .gorizontal-menu {
    justify-content: space-between;
  }

  @keyframes close {
    to {
      transform: translateX(-300px);
    }
  }

  .vertical-menu:not(.el-menu--collapse).v-leave-active {
    animation: close 0.4s ease-in;
  }
}

button.gorizontal-menu__aside-enter {
  @apply px-3;
  max-width: min(35vw, 8rem);
}
</style>
